

















































































































































































































































































































































































































































































































































































































































































































































































































































































































































import { Component, Vue, Model, Watch } from 'vue-property-decorator'
import {
  EDeadLineType,
  EPlanType,
  ERepeatType,
  EShareType,
  EventCalendarModel,
  IdName
} from '@/models'
import DatePickerLang from '@/helpers/DatePickerLang'
import CalendarService from '@/services/CalendarService'
import moment from 'moment'
import PreviewText from '@/helpers/PreviewText'
import CalendarColor from '@/helpers/CalendarColor'
import GroupService from '@/services/GroupService'
import GroupChatService from '@/services/GroupChatService'
//@ts-ignore
import _ from 'lodash'

@Component
export default class CreateEvent extends Vue {
  @Model('change', { type: String, default: '' })
  updateView!: string
  private confirmChange: any
  private allowChange: boolean = false
  private errorDate: boolean = false
  private errorSearchSettingDeviceMsg: boolean = false
  private errorSearchMemberMsg: boolean = false
  private isVisibleRepeat: boolean = false
  private isVisibleDuration: boolean = false
  private openPanelStartHour: boolean = false
  private openPanelStartMinute: boolean = false
  private openPanelEndHour: boolean = false
  private openPanelEndMinute: boolean = false
  private showSettingAdv: boolean = true
  private errorEndDeadLine: boolean = false
  private eventCalendar: EventCalendarModel = new EventCalendarModel()
  private start_hour: any = ''
  private start_minute: any = ''
  private end_hour: any = ''
  private end_minute: any = ''
  private input_search_member: string = ''
  private list_member_search: any[] = []
  private list_member_choose: any[] = []
  private input_search_setting_device: string = ''
  private list_setting_device_search: any[] = []
  private list_setting_device_choose: any[] = []
  private listGroup: IdName[] = []
  private list_group_choose: IdName[] = []
  private list_minute: any[] = [0, 5, 10, 15, 20, 25, 30, 35, 40, 45, 50, 55]
  private list_day_of_week_temp: any[] = []
  private list_day_of_week: any[] = []
  private list_day_of_month: any[] = []
  private activeBtn: string = ''
  private calendarColor: CalendarColor = new CalendarColor()
  private datePickProp: DatePickerLang = new DatePickerLang(this)
  private infoMess: string = ''
  private limitMemberSearch: number = 50
  private selectedGroup: IdName = new IdName()
  private ePlanType: any = EPlanType
  private eDeadLineType: any = EDeadLineType
  private eShareType: any = EShareType
  private iconChecked = require('@/assets/images/icon-checked.svg')
  private selectedGroupMemberChoose: IdName = new IdName()

  created() {
    this.init()
  }

  async init() {
    this.confirmNo()
    await this.searchMember()
    await this.searchSettingDevice()
    this.$route.params.update_view
      ? (this.updateView = this.$route.params.update_view)
      : ''
    this.handleEventDate()
    this.handleDayOfWeek()
    this.hanleListOfWeek()
    await this.getAllGroups()
  }

  checkRouterBeforLeave() {
    const startEnd = [
      this.start_hour,
      this.start_minute,
      this.end_hour,
      this.end_minute
    ]

    let currentEvent = this.eventCalendar
    currentEvent.plan_type = EPlanType.NORMAL

    return (
      _.isEqual(currentEvent, new EventCalendarModel()) &&
      _.isEqual(this.selectedGroupMemberChoose, new IdName()) &&
      _.isEqual(this.selectedGroup, new IdName()) &&
      !this.list_setting_device_choose.length &&
      startEnd.join('') === ''
    )
  }

  beforeRouteLeave(to: any, from: any, next: any) {
    //this case for modal search header
    if (document.querySelectorAll('#modal-search-header').length) {
      next()

      //normal case
    } else if (!this.allowChange && !this.checkRouterBeforLeave()) {
      if (from.name !== to.name) {
        this.confirmChange = next
        this.$bvModal.show('modal-confirm')
      }
    } else {
      next()
    }
  }

  confirm() {
    this.confirmChange()
  }

  confirmNo() {
    // ;(this.$parent.$parent as any).updateView = '_'
  }

  toggleShowAdvancedSetting() {
    this.showSettingAdv = !this.showSettingAdv
    if (!this.showSettingAdv) {
      this.list_member_choose = []
      this.list_setting_device_choose = []
      this.eventCalendar.share_type = EShareType.GROUP_TAG
      this.eventCalendar.accept_member_change_plan = false
    }
  }

  handleEventDate() {
    if (this.$route.params.event_start_date) {
      if (this.updateView === 'dayGrid') {
        this.$set(
          this.eventCalendar,
          'event_start_date',
          moment(this.$route.params.event_start_date).format('YYYY/MM/DD')
        )
      } else {
        this.$set(
          this.eventCalendar,
          'event_start_date',
          moment(this.$route.params.event_start_date).format('YYYY/MM/DD')
        )
        this.start_hour = moment(this.$route.params.event_start_date).format(
          'HH'
        )
        this.start_minute = moment(this.$route.params.event_start_date).format(
          'mm'
        )
      }
    }
    if (this.$route.params.event_end_date) {
      if (this.updateView === 'dayGrid') {
        this.$set(
          this.eventCalendar,
          'event_end_date',
          moment(this.$route.params.event_end_date)
            .subtract(1, 'days')
            .format('YYYY/MM/DD')
        )
      } else {
        this.$set(
          this.eventCalendar,
          'event_end_date',
          moment(this.$route.params.event_end_date).format('YYYY/MM/DD')
        )
        this.end_hour = moment(this.$route.params.event_end_date).format('HH')
        this.end_minute = moment(this.$route.params.event_end_date).format('mm')
      }
    }
  }

  handleDayOfWeek() {
    const dayOfWeek = [
      this.$t('calendar.create.day_of_week.sunday'),
      this.$t('calendar.create.day_of_week.monday'),
      this.$t('calendar.create.day_of_week.tuesday'),
      this.$t('calendar.create.day_of_week.wednesday'),
      this.$t('calendar.create.day_of_week.thursday'),
      this.$t('calendar.create.day_of_week.friday'),
      this.$t('calendar.create.day_of_week.saturday')
    ]

    this.list_day_of_week_temp = Array.from({ length: 7 }, (v, i) => i).map(
      item => {
        return {
          value: item,
          text: dayOfWeek[item]
        }
      }
    )

    this.list_day_of_month = Array.from({ length: 31 }, (v, i) => i + 1).map(
      item => {
        return {
          value: item,
          text: `${('0' + item).slice(-2)}${this.$t('calendar.create.day')}`
        }
      }
    )
  }

  hanleListOfWeek() {
    let first_day = this.$store.state.setting.setting.setting_display
      .first_day_of_week
    let value = first_day
    for (let index = 0; index < 7; index++) {
      this.list_day_of_week.push(this.list_day_of_week_temp[value])
      if (value < 6) {
        value++
      } else {
        value = 0
      }
    }
  }

  hanleGroupTagText(text: string) {
    return PreviewText.covertToPreviewText(text, 38).text
  }

  hanleMemberText(text: string) {
    return PreviewText.covertToPreviewText(text, 25).text
  }

  @Watch('updateView')
  changeViewCalendar() {
    if (this.updateView) {
      let changeViewCalendar: any = this.updateView
      this.$router.push({
        name: 'calendar',
        params: { changeViewCalendar }
      })
    }
  }

  @Watch('eventCalendar.plan_type')
  changeView(view: number) {
    this.$set(this.eventCalendar, 'plan_type', view)
    if (this.eventCalendar.plan_type === EPlanType.REPEAT) {
      this.isVisibleDuration = false
      this.isVisibleRepeat = true
      if (!this.eventCalendar.repeat_type) {
        this.$set(this.eventCalendar, 'repeat_type', ERepeatType.DAILY)
      }
      if (!this.eventCalendar.deadline_type) {
        this.$set(this.eventCalendar, 'deadline_type', EDeadLineType.INDEFINITE)
      }
    } else if (this.eventCalendar.plan_type === EPlanType.DURRATION) {
      this.isVisibleDuration = true
      this.isVisibleRepeat = false
    } else {
      this.isVisibleRepeat = false
      this.isVisibleDuration = false
    }
    this.eventCalendar.event_start_date = ''
    this.eventCalendar.event_end_date = ''
    this.eventCalendar.title = ''
    this.eventCalendar.memo = ''
    this.eventCalendar.event_color = '#FFFFFF'
    this.eventCalendar.repeat_type = ERepeatType.DAILY
    this.eventCalendar.every_week_day = undefined
    this.eventCalendar.every_month_day = undefined
    this.eventCalendar.deadline_type = EDeadLineType.INDEFINITE
    this.eventCalendar.end_deadline = ''
    this.start_hour = ''
    this.start_minute = ''
    this.end_hour = ''
    this.end_minute = ''
    this.errorDate = false
    this.errorEndDeadLine = false
    ;(this.$refs.dateEvent as any).reset()
  }

  @Watch('eventCalendar.share_type')
  watchEventShareType() {
    if (this.eventCalendar.share_type === EShareType.GROUP_TAG) {
      this.list_member_choose = []
      this.list_member_search = []
      this.selectedGroupMemberChoose = new IdName()
      // this.eventCalendar.accept_member_change_plan = false
    } else {
      this.list_group_choose = []
      this.selectedGroup = new IdName()
    }
  }

  @Watch('list_member_choose')
  watchListMemberChoose() {
    this.eventCalendar.accept_member_change_plan = !!this.list_member_choose
      .length
  }

  @Watch('selectedGroup.id')
  watchSelectedGroup() {
    this.eventCalendar.accept_member_change_plan = !!this.selectedGroup.id
  }

  @Watch('selectedGroupMemberChoose.id')
  watchSelectedGroupMemberChoose() {
    this.searchMember()
    if (this.selectedGroupMemberChoose.id) {
      const loginUser = this.$store.state.userInfo.user.info
      this.list_member_choose = [
        {
          userId: loginUser.id,
          userName: loginUser.name,
          choose: true,
          disable: true
        }
      ]
    }
  }

  async getAllGroups() {
    return GroupService.getGroupOrder(1, 99999999)
      .then(res => {
        if (res.status === 200) {
          this.listGroup = [
            {
              id: null,
              name: this.$t('calendar.create.placeholder_choose_group_tag')
            },
            ...res.data.data
          ]
        }
      })
      .catch(err => {
        console.log(err)
      })
  }

  handleSelectGroup(group: IdName) {
    this.selectedGroup = group
  }

  handleSelectMemberChooseGroup(group: IdName) {
    this.selectedGroupMemberChoose = group
  }

  async searchMember() {
    if (!this.selectedGroupMemberChoose.id) {
      this.list_member_search = []
      this.list_member_choose = []
      return
    }

    let lstMemberIdChoose: any[] = []
    lstMemberIdChoose.push(this.$store.state.userInfo.user.info.id)
    if (this.list_member_choose.length > 0) {
      this.list_member_choose.forEach((user: any) => {
        if (!lstMemberIdChoose.includes(user.userId)) {
          lstMemberIdChoose.push(user.userId)
        }
      })
    }
    // get api search all member by search input and user id not in  lstMemberIdChosse
    let userId: string = ''
    if (lstMemberIdChoose.length > 0) {
      lstMemberIdChoose.forEach((item: any) => {
        userId += `${item},`
      })
      userId = userId.slice(0, -1)
    }

    let dataSearchMember: any = {
      userIds: userId,
      userName: this.input_search_member.toString().trim(),
      limit: this.limitMemberSearch,
      page: 1,
      groupId: this.selectedGroupMemberChoose.id
    }

    return GroupChatService.getMemberByGroupId(dataSearchMember)
      .then((res: any) => {
        if (res.status === 200) {
          if (res.data.data.length > 0) {
            this.list_member_search = res.data.data.map((item: any) => {
              return {
                userId: item.id,
                userName: item.name,
                choose: false
              }
            })
          } else {
            this.errorSearchMemberMsg = true
            this.list_member_search = []
          }
        }
        setTimeout(() => {
          this.errorSearchMemberMsg = false
          clearTimeout()
        }, 3000)
      })
      .catch((err: any) => {
        console.log(err)
      })
  }

  chooseMember(user: any) {
    if (user.disable) return
    const userId = user.userId
    if (this.eventCalendar.share_type === EShareType.MEMBER_CHOOSE) {
      this.list_member_search.forEach((user: any) => {
        if (user.userId === userId) {
          user.choose = !user.choose
        }
      })
    }
  }

  chooseMemberRemove(user: any) {
    if (user.disable) return
    const userId = user.userId
    if (this.eventCalendar.share_type === EShareType.MEMBER_CHOOSE) {
      this.list_member_choose.forEach((user: any) => {
        if (user.userId === userId) {
          user.choose = !user.choose
        }
      })
    }
  }

  addMemberToListChoose() {
    // change lst member choose from lst member search to lst member choose
    if (this.eventCalendar.share_type === EShareType.MEMBER_CHOOSE) {
      this.list_member_choose = [
        ...this.list_member_choose,
        ...this.list_member_search.filter((user: any) => user.choose)
      ]

      // sort lst member choose by user name
      // this.list_member_choose = this.list_member_choose.sort(
      //   (a: any, b: any) => {
      //     if (a.userName < b.userName) {
      //       return -1
      //     } else {
      //       return 0
      //     }
      //   }
      // )

      // update lst member search
      this.list_member_search = this.list_member_search.filter(
        (user: any) => !user.choose
      )
    }
  }

  removeMemberFromListChoose() {
    if (this.eventCalendar.share_type === EShareType.MEMBER_CHOOSE) {
      // update lst member choose
      this.list_member_choose = this.list_member_choose.filter(
        (user: any) => user.choose
      )
      // send api search lst member
      this.searchMember()
    }
  }

  async searchSettingDevice() {
    let list_setting_device_id_choose: any = []
    if (this.list_setting_device_choose.length > 0) {
      this.list_setting_device_choose.forEach((item: any) => {
        if (!list_setting_device_id_choose.includes(item.id)) {
          list_setting_device_id_choose.push(item.id)
        }
      })
    }
    let setting_device_id: string = ''
    if (list_setting_device_id_choose.length > 0) {
      list_setting_device_id_choose.forEach((item: any) => {
        setting_device_id += `${item},`
      })
      setting_device_id = setting_device_id.slice(0, -1)
    }
    let data: any = {
      list_setting_device_ids: setting_device_id,
      group_name: this.input_search_setting_device.toString().trim(),
      limit: 50,
      page: 1
    }
    return CalendarService.getSettingDeviceOfGroup(data).then(response => {
      if (response.status === 200) {
        if (response.data.data.length > 0) {
          this.list_setting_device_search = response.data.data.map(
            (item: any) => {
              return {
                id: item.id,
                name: `${item.settingDeviceName} (${item.group_name})`,
                choose: false
              }
            }
          )
        } else {
          this.errorSearchSettingDeviceMsg = true
          this.list_setting_device_search = []
        }
      }
      setTimeout(() => {
        this.errorSearchSettingDeviceMsg = false
        clearTimeout()
      }, 3000)
    })
  }

  chooseSettingDevice(deviceId: any) {
    this.list_setting_device_search.forEach((device: any) => {
      if (device.id === deviceId) {
        device.choose = !device.choose
      }
    })
  }

  chooseSettingDeviceRemove(deviceId: any) {
    this.list_setting_device_choose.forEach((device: any) => {
      if (device.id === deviceId) {
        device.choose = !device.choose
      }
    })
  }

  addSettingDeviceToListChoose() {
    this.list_setting_device_search.forEach((item: any) => {
      if (item.choose) {
        this.list_setting_device_choose.push(item)
      }
    })
    this.list_setting_device_choose = this.list_setting_device_choose.sort(
      (a: any, b: any) => {
        if (a.name < b.name) {
          return -1
        } else {
          return 0
        }
      }
    )
    this.list_setting_device_search = this.list_setting_device_search.filter(
      (item: any) => {
        return item.choose === false
      }
    )
  }

  removeSettingDeviceFromListChoose() {
    this.list_setting_device_choose = this.list_setting_device_choose.filter(
      (item: any) => {
        return item.choose
      }
    )
    this.searchSettingDevice()
  }

  checkValidatePlanType() {
    if (this.eventCalendar.plan_type !== EPlanType.DURRATION) {
      if (
        this.eventCalendar.event_end_date < this.eventCalendar.event_start_date
      ) {
        this.errorDate = true
      }
      if (
        this.eventCalendar.event_end_date ===
        this.eventCalendar.event_start_date
      ) {
        if (this.end_hour < this.start_hour) {
          this.errorDate = true
        }
        if (this.end_hour === this.start_hour) {
          if (this.end_minute <= this.start_minute) {
            this.errorDate = true
          }
        }
      }
    } else {
      if (
        this.eventCalendar.event_end_date < this.eventCalendar.event_start_date
      ) {
        this.errorDate = true
      } else {
        this.errorDate = false
      }
    }
  }

  checkEndDeadline() {
    if (this.eventCalendar.deadline_type === EDeadLineType.TIME_LIMIT) {
      let current_date = moment().format('YYYY/MM/DD')
      if (this.eventCalendar.end_deadline <= current_date) {
        this.errorEndDeadLine = true
      }
    }
  }

  async createEventCalendar() {
    this.checkValidatePlanType()
    this.checkEndDeadline()
    if (!this.errorDate && !this.errorEndDeadLine) {
      let event_start_time = this.start_hour + ':' + this.start_minute
      let event_end_time = this.end_hour + ':' + this.end_minute
      let eventCalendar: any = {}
      let list_id_group_choose = [this.selectedGroup.id]

      let list_id_member_choose = this.list_member_choose.map(
        item => item.userId
      )
      let list_id_setting_device_choose = this.list_setting_device_choose.map(
        item => item.id
      )

      if (this.eventCalendar.plan_type === EPlanType.NORMAL) {
        eventCalendar.event_start_date =
          this.eventCalendar.event_start_date + ' ' + event_start_time
        eventCalendar.event_end_date =
          this.eventCalendar.event_end_date + ' ' + event_end_time
        eventCalendar.repeat_type = ERepeatType.DAILY
        eventCalendar.every_week_day = null
        eventCalendar.every_month_day = null
        eventCalendar.deadline_type = EDeadLineType.INDEFINITE
        eventCalendar.end_deadline = null
      }

      if (this.eventCalendar.plan_type === EPlanType.DURRATION) {
        const fm = 'YYYY/MM/DD'
        const endDate = this.eventCalendar.event_end_date
        eventCalendar.event_start_date = this.eventCalendar.event_start_date
        eventCalendar.event_end_date = this.moment(endDate, fm)
          .add(1, 'd')
          .format(fm)
        eventCalendar.repeat_type = ERepeatType.DAILY
        eventCalendar.every_week_day = null
        eventCalendar.every_month_day = null
        eventCalendar.deadline_type = EDeadLineType.INDEFINITE
        eventCalendar.end_deadline = null
      }

      if (this.eventCalendar.plan_type === EPlanType.REPEAT) {
        if (
          this.eventCalendar.repeat_type === ERepeatType.DAILY ||
          this.eventCalendar.repeat_type === ERepeatType.DAILY_NO_WEEKEND
        ) {
          eventCalendar.every_week_day = null
          eventCalendar.every_month_day = null
        } else if (this.eventCalendar.repeat_type === ERepeatType.WEEKLY) {
          eventCalendar.every_week_day = this.eventCalendar.every_week_day
          eventCalendar.every_month_day = null
        } else {
          eventCalendar.every_month_day = this.eventCalendar.every_month_day
          eventCalendar.every_week_day = null
        }
        this.eventCalendar.deadline_type === EDeadLineType.INDEFINITE
          ? (eventCalendar.end_deadline = null)
          : (eventCalendar.end_deadline = moment(
              this.eventCalendar.end_deadline
            ).format('YYYY/MM/DD 23:59:59'))
        let current_date = moment().format('YYYY/MM/DD')
        eventCalendar.event_start_date = current_date + ' ' + event_start_time
        eventCalendar.event_end_date = current_date + ' ' + event_end_time
        eventCalendar.repeat_type = this.eventCalendar.repeat_type
        eventCalendar.deadline_type = Number(this.eventCalendar.deadline_type)
      }

      if (this.eventCalendar.share_type === EShareType.GROUP_TAG) {
        if (list_id_group_choose.toString() === ',') {
          eventCalendar.list_group_choose = null
        } else {
          eventCalendar.list_group_choose = list_id_group_choose.toString()
        }
        eventCalendar.list_member_choose = null
      } else {
        eventCalendar.group_id_member = this.selectedGroupMemberChoose.id
        eventCalendar.list_member_choose = list_id_member_choose.toString()
        eventCalendar.list_group_choose = null
      }

      if (!this.showSettingAdv) {
        eventCalendar.share_type = EShareType.GROUP_TAG
        eventCalendar.list_group_choose = null
        eventCalendar.list_member_choose = null
        eventCalendar.list_setting_device = null
        eventCalendar.group_id_member = null
        eventCalendar.accept_member_change_plan = false
      } else {
        eventCalendar.share_type = this.eventCalendar.share_type
        eventCalendar.accept_member_change_plan = this.eventCalendar.accept_member_change_plan
        eventCalendar.list_setting_device = list_id_setting_device_choose.toString()
      }

      eventCalendar.plan_type = this.eventCalendar.plan_type
      eventCalendar.title = this.eventCalendar.title
      eventCalendar.memo = this.eventCalendar.memo
      eventCalendar.created_by = this.$store.state.userInfo.user.info.id
      eventCalendar.event_color = this.eventCalendar.event_color
      await CalendarService.createCalendarEvent(eventCalendar)
        .then((res: any) => {
          if (res.status === 200) {
            this.allowChange = true
            this.$bvModal.show('modal-success')
          }
        })
        .catch((err: any) => {
          if (err.response.status === 422) {
            this.$bvModal.show('modal-error-event-retweet-device')
          } else {
            this.$bvModal.show('modal-error')
          }
        })
    }
  }

  goToMyCalendar() {
    this.$router.push({
      name: 'calendar'
    })
  }

  changeStartHour() {
    this.openPanelStartHour = false
  }

  changeStartMinute() {
    this.openPanelStartMinute = false
  }

  changeEndHour() {
    this.openPanelEndHour = false
  }

  changeEndMinute() {
    this.openPanelEndMinute = false
  }

  getStartDateError() {
    return [
      (this.$refs as any).startDateEvent?.errors[0],
      (this.$refs as any).startHourEvent?.errors[0],
      (this.$refs as any).startMinuteEvent?.errors[0]
    ].filter(item => item)
  }

  getDeadLineError() {
    return [(this.$refs as any).endDeadline?.errors[0]].filter(item => item)
  }

  getEndDateError() {
    return [
      (this.$refs as any).endDateEvent?.errors[0],
      (this.$refs as any).endHourEvent?.errors[0],
      (this.$refs as any).endMinuteEvent?.errors[0]
    ].filter(item => item)
  }

  handleTilte(isStart: boolean) {
    switch (this.eventCalendar.plan_type) {
      case EPlanType.NORMAL:
        return isStart
          ? this.$t('calendar.create.datetime_start')
          : this.$t('calendar.create.datetime_end')
      case EPlanType.DURRATION:
        return isStart
          ? this.$t('calendar.create.date_start')
          : this.$t('calendar.create.date_end')
      case EPlanType.REPEAT:
        return isStart
          ? this.$t('calendar.create.time_start')
          : this.$t('calendar.create.time_end')
      default:
        return ''
    }
  }
}
